import React from "react"
import styled from "styled-components"
import { graphql, navigate } from "gatsby"

import Layout from "../../components/layout/Layout"
import { MetadataReadonly } from "../../types/contentfulTypes"
import * as TextStyles from "../../components/styles/TextStyles"
import PillButton from "../../components/buttons/PillButton"
import PurchaseButton from "../../components/buttons/PurchaseButton"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
  }
}

const Success = (props: Props) => {
  const { data } = props

  return (
    <Layout {...data}>
      <BackgroundContainer>
        <Wave />
        <Wave2 />
      </BackgroundContainer>
      <Wrapper>
        <Card>
          <Background />
          <Logo src="/images/icons/check.svg" alt="logo" />
          <Title>Success!</Title>
          <Illustration src="/images/illustrations/goal.png" alt="goals" />
          <Caption>Thank you for purchasing Angle 4. We are excited to have you onboard!</Caption>
          <PillButton title="CONTINUE" onClick={() => navigate("/")} />
        </Card>
        <TweetCaption>
          Tweet "Just bought Angle 4: 5000+ device vector mockups for Sketch, Figma and XD."
        </TweetCaption>
        <a
          href={`https://twitter.com/intent/tweet?url=https://angle.sh/&text=Just bought Angle 4: 5000+ device vector mockups for Sketch, Figma and XD.`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <PurchaseButton title="Tweet this" icon="/images/icons/tweet.svg" />
        </a>
      </Wrapper>
    </Layout>
  )
}

export default Success

export const query = graphql`
  query SuccessQuery {
    contentfulMetadata(slug: { eq: "success" }) {
      ...MetadataFragment
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  display: grid;
  max-width: 1234px;
  width: 100%;
  padding: 300px 20px;
  justify-content: stretch;
  margin: 0 auto;
  z-index: 2;

  a {
    text-decoration: none;
    width: fit-content;
    margin: 0 auto;
  }

  .purchase {
    margin: 0 auto;
    margin-top: 40px;
  }
`

const Card = styled.div`
  position: relative;
  display: grid;
  gap: 10px;
  max-width: 570px;
  width: 100%;
  background: rgba(205, 192, 251, 0.3);
  box-shadow: 0px 16px 30px rgba(69, 42, 124, 0.19);
  backdrop-filter: blur(40px);
  border-radius: 35px;
  padding: 20px;
  margin: 0 auto;
`

const Background = styled.div`
  position: absolute;
  background: linear-gradient(96.43deg, #3630aa 10.26%, #ac6dcd 74.19%);
  box-shadow: 0px 8px 11px rgba(69, 42, 124, 0.35);
  width: 100%;
  height: 126px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
`

const Logo = styled.img`
  position: relative;
  width: 42px;
  height: 42px;
  margin: 0 auto;
`

const Title = styled(TextStyles.BodyIntro)`
  position: relative;
  margin: 0 auto;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
`

const Illustration = styled.img`
  position: relative;
  width: 70px;
  height: 82px;
  margin: 0 auto;
`

const Caption = styled(TextStyles.MediumText)`
  max-width: 410px;
  margin: 0 auto;
  text-align: center;
`

const TweetCaption = styled(TextStyles.MediumText)`
  max-width: 550px;
  text-align: center;
  margin: 0 auto;
  padding-top: 100px;

  span {
    color: #8338a8;
  }
`

const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 1293px;
  overflow: hidden;
`

const Wave = styled.div`
  position: absolute;
  width: 100%;
  height: 1600px;
  bottom: -400px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/images/backgrounds/success-wave.webp");
  background-size: 2100px;
`

const Wave2 = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  background-position: top center;
  background-repeat: no-repeat;
  background-image: url("/images/backgrounds/success-wave2.webp");
  background-size: 1440px;
`
